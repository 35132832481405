import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Mentorship from '../components/mentorship'
import SEO from '../components/seo'
import ContentContainer from '../containers/content'

export const query = graphql`
  query MentorshipTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    mentorship: sanityMentorship(id: { eq: $id }) {
      id
      title
      artist
      venue
      location
      startDate
      endDate
      previewImageSize {
        widthDesktop
      }
      tags {
        _id
        title
      }
      mainImage {
        ...SanityImage
        asset {
          fluid(maxWidth: 2400) {
            ...GatsbySanityImageFluid
          }
        }
        title
        credits
        alt
      }
      pressLinks {
        title
        externalLink
        download {
          asset {
            url
          }
        }
      }
      slug {
        current
      }
      metaDescription
      openGraph {
        image {
          ...SanityImage
        }
      }
      _rawFormattedTitle
      _rawPressLinks(resolveReferences: { maxDepth: 5 })
      _rawGallerySlides(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`

const MentorshipTemplate = props => {
  const { data, errors } = props
  const site = (data || {}).site
  const siteOgImage = site.openGraph.image
  const mentorship = data && data.mentorship
  const mentorshipOgImage = mentorship.openGraph && mentorship.openGraph.image
  const SEOtitle = `${mentorship.title}, ${mentorship.artist}`

  return (
    <ContentContainer section="mentorship" singleView>
      {errors && <SEO title="GraphQL Error" />}
      {mentorship && (
        <SEO
          title={SEOtitle || site.title}
          description={mentorship.metaDescription || site.description}
          keywords={site.keywords}
          image={mentorshipOgImage || siteOgImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {mentorship && <Mentorship {...mentorship} />}
    </ContentContainer>
  )
}

export default MentorshipTemplate
