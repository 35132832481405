import { format } from 'date-fns'
import React from 'react'
import { isBrowser, partInViewport } from '../lib/helpers'
import PortableText from './portableText'
import GallerySlider from './gallery-slider'
import classNames from 'classnames'
import Modal from 'react-modal'
import { CSSTransition } from 'react-transition-group'
import noScroll from 'no-scroll'

class Mentorship extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isBodySliderRendereded: false,
      isHeaderSliderVisible: true,
      isOverlaySliderOpen: false,
      isOverlaySliderVisible: true,
      slideIndex: 0,
    }
    this.toggleOverlaySlider = this.toggleOverlaySlider.bind(this)
  }

  onScroll = () => {
    const el = document.getElementById('gallery-slider-header')
    if (el) {
      this.setState({
        isHeaderSliderVisible: partInViewport(el, 0, 136),
      })
    }
  }

  toggleBodySlider = isVisible => {
    this.setState({
      isHeaderSliderVisible: isVisible,
    })
  }

  setSlideIndex = i => {
    this.setState({
      slideIndex: i,
    })
  }

  toggleOverlaySlider = () => {
    const { isOverlaySliderOpen, isOverlaySliderVisible } = this.state
    // toggle slider open
    this.setState({
      isOverlaySliderOpen: !isOverlaySliderOpen,
    })
    if (isOverlaySliderVisible) {
      document.body.classList.add('overlay-slider-transition-delay')
      setTimeout(() => {
        document.body.classList.remove('overlay-slider-transition-delay')
      }, 400)
    }
  }

  renderPressLink(link) {
    const _link = link.article ? link.article : link
    return (
      <a
        className="btn"
        href={_link.download ? _link.download.asset.url : _link.externalLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {_link.title}
      </a>
    )
  }

  render() {
    const {
      artist,
      venue,
      location,
      startDate,
      endDate,
      title,
      tags,
      _rawFormattedTitle,
      _rawPressLinks,
      _rawGallerySlides,
      _rawBody,
    } = this.props
    const startYear = format(startDate, 'YYYY')
    const classes = classNames({
      'is-body-slider-rendered': this.state.isBodySliderRendereded,
      'is-body-slider-visible': !this.state.isHeaderSliderVisible,
    })

    return (
      <div className="page-container">
        <div className={`mentorship ${classes}`}>
          <div className="mentorship__header">
            <h1 className="mentorship__title">
              {title && artist && (
                <>
                  <span className="mentorship-title__name">
                    <em>
                      {_rawFormattedTitle ? (
                        <PortableText blocks={_rawFormattedTitle} />
                      ) : (
                        <>{title}</>
                      )}
                    </em>
                    {artist}
                  </span>
                </>
              )}
              {venue && (
                <>
                  <span>{venue}</span>
                  <br />
                </>
              )}
              {location && (
                <>
                  <span>{location}</span>
                  <br />
                </>
              )}
              {startDate && endDate && (
                <>
                  <span>
                    <time dateTime={startDate}>{format(startDate, 'DD.MM')}</time>
                    .–
                    <time dateTime={endDate}>{format(endDate, 'DD.MM.YYYY')}</time>
                  </span>
                </>
              )}
            </h1>
            <div className="mentorship__tags">
              {tags &&
                tags.map(tag => (
                  <div className="btn content-tag content-tag--info">{tag.title}</div>
                ))}
            </div>
          </div>
          {/* header slider */}
          {_rawGallerySlides && (
            <GallerySlider
              type="header"
              title={title}
              artist={artist}
              location={location}
              startYear={startYear}
              slides={_rawGallerySlides}
              slideIndex={this.state.slideIndex}
              setSlideIndex={this.setSlideIndex}
              toggleOverlaySlider={this.toggleOverlaySlider}
            />
          )}
          {/* press links */}
          {_rawPressLinks && (
            <div className="collaboration__links">
              {_rawPressLinks.map(link => this.renderPressLink(link))}
            </div>
          )}
          {_rawGallerySlides && (
            <GallerySlider
              type="body"
              title={title}
              artist={artist}
              location={location}
              startYear={startYear}
              slides={_rawGallerySlides}
              pressLinks={_rawPressLinks}
              slideIndex={this.state.slideIndex}
              setSlideIndex={this.setSlideIndex}
              toggleOverlaySlider={this.toggleOverlaySlider}
              isMentorsip
            />
          )}
          {_rawGallerySlides && (
            <CSSTransition in={this.state.isOverlaySliderOpen} timeout={400}>
              <Modal
                className={`gallery-slider__overlay-container`}
                closeTimeoutMS={400}
                isOpen={this.state.isOverlaySliderOpen}
                ref={this.targetRef}
              >
                <GallerySlider
                  type="overlay"
                  title={title}
                  artist={artist}
                  location={location}
                  startYear={startYear}
                  slides={_rawGallerySlides}
                  slideIndex={this.state.slideIndex}
                  setSlideIndex={this.setSlideIndex}
                  toggleOverlaySlider={this.toggleOverlaySlider}
                />
              </Modal>
            </CSSTransition>
          )}
          <div className="mentorship__body">
            <div className="mentorship__body-inner">
              {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    if (!isBrowser()) {
      return
    }
    document.addEventListener('scroll', this.onScroll)
  }
  componentDidUpdate() {
    if (this.state.isOverlaySliderOpen) {
      noScroll.on()
    } else {
      noScroll.off()
    }
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
    noScroll.off()
  }
}

export default Mentorship
